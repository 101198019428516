exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-profiles-js": () => import("./../../../src/templates/profiles.js" /* webpackChunkName: "component---src-templates-profiles-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-scholarship-js": () => import("./../../../src/templates/scholarship.js" /* webpackChunkName: "component---src-templates-scholarship-js" */)
}

